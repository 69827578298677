import {
  addLineItems,
  checkout,
  clearCart,
  getCart,
  loadCart,
  onAddLineItems,
  onBeforeCheckout,
  onCartError,
  onClearCart,
  onUpdateCart,
  onUpdateLineItem,
  setRootRoute,
  updateCart,
  updateLineItem,
} from '@/cart/cart-store';
import '@/components/AccordionRegister';
import '@/components/cart/CartItemsCountRegister';
import '@/components/cart/cartViewRegister';
import '@/components/drawers';
import {endDrawer, startDrawer} from '@/components/drawers';
import '@/components/newsletterForm';
import {getFeatureFlag} from '@/feature-flags/feature-flags-store';
import {getProduct} from '@/product/product-store';
import '@/scripts/navbar';
import {
  getProductSellingPlans,
  getSellingPlan,
} from '@/selling-plan/selling-plan-store';
import {overrideSettings} from '@/settings/settings-store';
import {logInfo} from '@/utilities/log';
// Enables feature flag tracking in liquid
import '@/components/CheckFeatureFlag';
import '@/components/locale-dialog/LocaleDialogRegister';
import '@/components/LocalizedStringLoader';
import {getLocalizedStrings} from '@/localization/localization-store';
import {effect} from '@preact/signals';
import 'vite/modulepreload-polyfill';

onAddLineItems((cart, items, input) => {
  logInfo('AddLineItems', {
    cart,
    addedItems: items,
    itemsInput: input,
    cartToken: cart?.token,
  });
});

onBeforeCheckout((cart) => {
  const hasNullSellingPlans = cart?.items.some(
    (lineItem) => !lineItem.selling_plan_allocation
  );
  logInfo('BeginCheckout', {
    cart,
    hasNullSellingPlans,
    cartToken: cart?.token,
  });
});

/**
 * If the root route of the store changes, update the cart store.
 * This ensures the cart is always translated to the current language and locale.
 */
effect(async () => {
  const rootRoute = getLocalizedStrings().storeRootRoute;
  if (!rootRoute) return;

  await setRootRoute(rootRoute);
});

(window as unknown as Window & {startDrawer: typeof startDrawer}).startDrawer =
  startDrawer;

(window as unknown as Window & {endDrawer: typeof endDrawer}).endDrawer =
  endDrawer;

(window as unknown as Window & {cart: unknown}).cart = {
  addLineItems,
  checkout,
  clearCart,
  getCart,
  loadCart,
  onAddLineItems,
  onBeforeCheckout,
  onCartError,
  onClearCart,
  onUpdateCart,
  onUpdateLineItem,
  updateCart,
  updateLineItem,
};

(window as unknown as Window & {product: unknown}).product = {
  getProduct,
};

(window as unknown as Window & {settings: unknown}).settings = {
  overrideSettings,
};

(window as unknown as Window & {plans: unknown}).plans = {
  getProductSellingPlans,
  getSellingPlan,
};

(window as unknown as Window & {featureFlags: unknown}).featureFlags = {
  getFeatureFlag,
};
